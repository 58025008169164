/**
 * @description 从 blog-post.js 复制过来，只修改了 design=>hobby
 */
import React from 'react'
import { Link, graphql } from 'gatsby'

import SEO from '../components/seo'
import Layout from '../components/Layout'

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMarkdownRemark.edges
    const { currentPage, numPages } = this.props.pageContext
    const isFirst = currentPage === 1
    const isLast = currentPage === numPages
    const prevPage = currentPage - 1 === 1 ? '/' : (currentPage - 1).toString()
    const nextPage = (currentPage + 1).toString()

    return (
      <Layout location={this.props.location} title={siteTitle} extraClassname="container-design">
        <SEO
          title={siteTitle}
          keywords={[`blog`, `dwb`, `董文博`, `dwbbb`, `dwbbb.com`,`javascript`, `hobby`]}
        />
        {posts.map(({ node }) => {
          const title = node.frontmatter.title || node.fields.slug
          return (
            <article className="m-post" key={node.fields.slug} onClick={() => window.open(node.fields.slug, '_self')}>
                {
                  node.frontmatter.photos && node.frontmatter.photos.length && node.frontmatter.photos[0] ?
                  <div className="m-post-img" style={{'backgroundImage': `url(${node.frontmatter.photos && node.frontmatter.photos.length && node.frontmatter.photos[0]})`}} /> : null
                }
                <div className="m-post-content">
                  <h3 className="title">{title}</h3>
                  <small>{node.frontmatter.date}</small>
                  <p dangerouslySetInnerHTML={{ __html: node.excerpt }} />
                </div>
            </article>
          )
        })}
        {/* <ul
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
            alignItems: 'center',
            listStyle: 'none',
            padding: 0,
          }}
        >
          {!isFirst && (
            <Link to={prevPage} rel="prev">
              ← Previous Page
            </Link>
          )}
          {Array.from({ length: numPages }, (_, i) => (
            <li
              key={`pagination-number${i + 1}`}
              style={{
                margin: '0 8px',
              }}
            >
              <Link
                to={`/${i === 0 ? '' : i + 1}`}
                style={{
                  textDecoration: 'none',
                  color: i + 1 === currentPage ? '#ffffff' : '',
                  background: i + 1 === currentPage ? '#007acc' : '',
                  padding: '.5em'
                }}
              >
                {i + 1}
              </Link>
            </li>
          ))}
          {!isLast && (
            <Link to={nextPage} rel="next">
              Next Page →
            </Link>
          )}
        </ul> */}
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query hobbyPageQuery($skip: Int!, $limit: Int!) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
      filter: {
        frontmatter: {
          type: {
            eq: "hobby"
          }
        }
      }
    ) {
      edges {
        node {
          excerpt(
            format: PLAIN
            pruneLength: 90
            truncate: true
          )
          fields {
            slug
          }
          frontmatter {
            date(formatString: "DD MMMM, YYYY")
            title
            type
            photos
            tags
          }
        }
      }
    }
  }
`
